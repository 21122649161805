import React, {lazy, Suspense} from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";

const Index = lazy(() => import("./pages/index"));
const Download = lazy(() => import("./pages/download"));
const Login = lazy(() => import("./pages/login"));
const Navbar = lazy(() => import("./components/Navbar"));
const ServicesAll = lazy(() => import("./pages/services/ServicesAll"));
const ServicesChatGPT = lazy(() => import("./pages/services/ChatGPT"));
const ServicesAdHeart = lazy(() => import("./pages/services/AdHeart"));
const ServicesFreepik = lazy(() => import("./pages/services/Freepik"));
const ServicesTgstat = lazy(() => import("./pages/services/Tgstat"));
const ServicesEnvato = lazy(() => import("./pages/services/Envato"));
const ServicesSegmentoTarget = lazy(() => import("./pages/services/Segmento"));
const ServicesPipiads = lazy(() => import("./pages/services/Pipiads"));
const ServicesTelemetr = lazy(() => import("./pages/services/Telemetr"));
const ServicesSupa = lazy(() => import("./pages/services/Supa"));
const ServicesMidjourney = lazy(() => import("./pages/services/Midjourney"));
const ServicesLeonardoAI = lazy(() => import("./pages/services/LeonardoAI"));
const ServicesSerpstat = lazy(() => import("./pages/services/SerpStat"));
const ServicesSemrush = lazy(() => import("./pages/services/Semrush"));
const ServicesCanva = lazy(() => import("./pages/services/Canva"));
const InDevelopment = lazy(() => import("./pages/inDev"));

function App() {
  return (
    <BrowserRouter>
      <Navbar/>
      <Routes>
        <Route path="/" element={<Suspense><Index /></Suspense>} />
        <Route path="/download" element={<Suspense><Download /></Suspense>} />
        <Route path="/services" element={<Suspense><ServicesAll /></Suspense>} />
        <Route path="/services/chatgpt" element={<Suspense><ServicesChatGPT /></Suspense>} />
        <Route path="/services/adheart" element={<Suspense><ServicesAdHeart /></Suspense>} />
        <Route path="/services/freepik" element={<Suspense><ServicesFreepik /></Suspense>} />
        <Route path="/services/tgstat" element={<Suspense><ServicesTgstat /></Suspense>} />
        <Route path="/services/envato-elements" element={<Suspense><ServicesEnvato /></Suspense>} />
        <Route path="/services/segmento-target" element={<Suspense><ServicesSegmentoTarget /></Suspense>} />
        <Route path="/services/pipiads" element={<Suspense><ServicesPipiads /></Suspense>} />
        <Route path="/services/telemetr" element={<Suspense><ServicesTelemetr /></Suspense>} />
        <Route path="/services/supa" element={<Suspense><ServicesSupa /></Suspense>} />
        <Route path="/services/midjourney" element={<Suspense><ServicesMidjourney /></Suspense>} />
        <Route path="/services/leonardo-ai" element={<Suspense><ServicesLeonardoAI /></Suspense>} />
        <Route path="/services/serpstat" element={<Suspense><ServicesSerpstat /></Suspense>} />
        <Route path="/services/semrush" element={<Suspense><ServicesSemrush /></Suspense>} />
        <Route path="/services/canva" element={<Suspense><ServicesCanva /></Suspense>} />
        <Route path="/in-development" element={<Suspense><InDevelopment /></Suspense>} />
        <Route path="/login" element={<Suspense><Login /></Suspense>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;